import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './project.reducer';

export const ProjectDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const projectEntity = useAppSelector(state => state.project.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="projectDetailsHeading">Project</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{projectEntity.id}</dd>
          <dt>
            <span id="uuid">Uuid</span>
          </dt>
          <dd>{projectEntity.uuid}</dd>
          <dt>
            <span id="name">Name</span>
          </dt>
          <dd>{projectEntity.name}</dd>
          <dt>
            <span id="pipelineName">Pipeline Name</span>
          </dt>
          <dd>{projectEntity.pipelineName}</dd>
          <dt>
            <span id="automaticallyProcessed">Automatically Processed</span>
          </dt>
          <dd>{projectEntity.automaticallyProcessed ? 'true' : 'false'}</dd>
          <dt>
            <span id="s3Bucket">S 3 Bucket</span>
          </dt>
          <dd>{projectEntity.s3Bucket}</dd>
          <dt>
            <span id="status">Status</span>
          </dt>
          <dd>{projectEntity.status}</dd>
          <dt>
            <span id="plannedKm">Planned Km</span>
          </dt>
          <dd>{projectEntity.plannedKm}</dd>
          <dt>
            <span id="plannedDataCaptureStart">Planned Data Capture Start</span>
          </dt>
          <dd>
            {projectEntity.plannedDataCaptureStart ? (
              <TextFormat value={projectEntity.plannedDataCaptureStart} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="plannedDataCaptureEnd">Planned Data Capture End</span>
          </dt>
          <dd>
            {projectEntity.plannedDataCaptureEnd ? (
              <TextFormat value={projectEntity.plannedDataCaptureEnd} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>Road Network</dt>
          <dd>{projectEntity.roadNetwork ? projectEntity.roadNetwork.id : ''}</dd>
          <dt>Processing Result</dt>
          <dd>{projectEntity.processingResult ? projectEntity.processingResult.id : ''}</dd>
          <dt>Organization</dt>
          <dd>{projectEntity.organization ? projectEntity.organization.id : ''}</dd>
          <dt>Country</dt>
          <dd>{projectEntity.country ? projectEntity.country.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/project" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/project/${projectEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

export default ProjectDetail;
