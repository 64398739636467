import dayjs from 'dayjs';
import { IProcessingResult } from 'app/shared/model/processing-result.model';
import { ICapture } from 'app/shared/model/capture.model';
import { IOrganization } from 'app/shared/model/organization.model';
import { IProject } from 'app/shared/model/project.model';
import { UploadStatus } from 'app/shared/model/enumerations/upload-status.model';

export interface IUpload {
  id?: number;
  uuid?: string;
  name?: string | null;
  dateStarted?: string | null;
  dateFinished?: string | null;
  status?: UploadStatus | null;
  uploaderPerson?: string | null;
  processingResult?: IProcessingResult | null;
  captures?: ICapture[] | null;
  organization?: IOrganization | null;
  project?: IProject | null;
}

export const defaultValue: Readonly<IUpload> = {};
