import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import OgcApiPointcloud from './ogc-api-pointcloud';
import OgcApiPointcloudDetail from './ogc-api-pointcloud-detail';
import OgcApiPointcloudUpdate from './ogc-api-pointcloud-update';
import OgcApiPointcloudDeleteDialog from './ogc-api-pointcloud-delete-dialog';

const OgcApiPointcloudRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<OgcApiPointcloud />} />
    <Route path="new" element={<OgcApiPointcloudUpdate />} />
    <Route path=":id">
      <Route index element={<OgcApiPointcloudDetail />} />
      <Route path="edit" element={<OgcApiPointcloudUpdate />} />
      <Route path="delete" element={<OgcApiPointcloudDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default OgcApiPointcloudRoutes;
