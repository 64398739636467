import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './capture.reducer';

export const CaptureDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const captureEntity = useAppSelector(state => state.capture.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="captureDetailsHeading">Capture</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{captureEntity.id}</dd>
          <dt>
            <span id="uuid">Uuid</span>
          </dt>
          <dd>{captureEntity.uuid}</dd>
          <dt>
            <span id="name">Name</span>
          </dt>
          <dd>{captureEntity.name}</dd>
          <dt>
            <span id="s3Path">S 3 Path</span>
          </dt>
          <dd>{captureEntity.s3Path}</dd>
          <dt>
            <span id="status">Status</span>
          </dt>
          <dd>{captureEntity.status}</dd>
          <dt>
            <span id="processingStatus">Processing Status</span>
          </dt>
          <dd>{captureEntity.processingStatus}</dd>
          <dt>
            <span id="dateCreated">Date Created</span>
          </dt>
          <dd>
            {captureEntity.dateCreated ? <TextFormat value={captureEntity.dateCreated} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="captureStartDate">Capture Start Date</span>
          </dt>
          <dd>
            {captureEntity.captureStartDate ? (
              <TextFormat value={captureEntity.captureStartDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="captureEndDate">Capture End Date</span>
          </dt>
          <dd>
            {captureEntity.captureEndDate ? <TextFormat value={captureEntity.captureEndDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="lengthInKm">Length In Km</span>
          </dt>
          <dd>{captureEntity.lengthInKm}</dd>
          <dt>
            <span id="s3ThumbnailImage">S 3 Thumbnail Image</span>
          </dt>
          <dd>{captureEntity.s3ThumbnailImage}</dd>
          <dt>
            <span id="osmLvl8">Osm Lvl 8</span>
          </dt>
          <dd>{captureEntity.osmLvl8}</dd>
          <dt>Processing Result</dt>
          <dd>{captureEntity.processingResult ? captureEntity.processingResult.id : ''}</dd>
          <dt>Organization</dt>
          <dd>{captureEntity.organization ? captureEntity.organization.id : ''}</dd>
          <dt>Country</dt>
          <dd>{captureEntity.country ? captureEntity.country.id : ''}</dd>
          <dt>Upload</dt>
          <dd>{captureEntity.upload ? captureEntity.upload.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/capture" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/capture/${captureEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

export default CaptureDetail;
