import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import OgcApiPanoramaSequence from './ogc-api-panorama-sequence';
import OgcApiPanoramaSequenceDetail from './ogc-api-panorama-sequence-detail';
import OgcApiPanoramaSequenceUpdate from './ogc-api-panorama-sequence-update';
import OgcApiPanoramaSequenceDeleteDialog from './ogc-api-panorama-sequence-delete-dialog';

const OgcApiPanoramaSequenceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<OgcApiPanoramaSequence />} />
    <Route path="new" element={<OgcApiPanoramaSequenceUpdate />} />
    <Route path=":id">
      <Route index element={<OgcApiPanoramaSequenceDetail />} />
      <Route path="edit" element={<OgcApiPanoramaSequenceUpdate />} />
      <Route path="delete" element={<OgcApiPanoramaSequenceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default OgcApiPanoramaSequenceRoutes;
