import organization from 'app/entities/organization/organization.reducer';
import project from 'app/entities/project/project.reducer';
import awsRegion from 'app/entities/aws-region/aws-region.reducer';
import stream from 'app/entities/stream/stream.reducer';
import upload from 'app/entities/upload/upload.reducer';
import country from 'app/entities/country/country.reducer';
import statusUpdateHistory from 'app/entities/status-update-history/status-update-history.reducer';
import projectConf from 'app/entities/project-conf/project-conf.reducer';
import roadNetwork from 'app/entities/road-network/road-network.reducer';
import capture from 'app/entities/capture/capture.reducer';
import ogcApiPointscenePointcloud from 'app/entities/ogc-api-pointscene-pointcloud/ogc-api-pointscene-pointcloud.reducer';
import ogcApiPointscenePanorama from 'app/entities/ogc-api-pointscene-panorama/ogc-api-pointscene-panorama.reducer';
import roadNetworkHistory from 'app/entities/road-network-history/road-network-history.reducer';
import ogcApiStreamTrajectory from 'app/entities/ogc-api-stream-trajectory/ogc-api-stream-trajectory.reducer';
import ogcApiPointcloud from 'app/entities/ogc-api-pointcloud/ogc-api-pointcloud.reducer';
import ogcApiOrthoStrip from 'app/entities/ogc-api-ortho-strip/ogc-api-ortho-strip.reducer';
import ogcApiPanoramaSequence from 'app/entities/ogc-api-panorama-sequence/ogc-api-panorama-sequence.reducer';
import processingResult from 'app/entities/processing-result/processing-result.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  organization,
  project,
  awsRegion,
  stream,
  upload,
  country,
  statusUpdateHistory,
  projectConf,
  roadNetwork,
  capture,
  ogcApiPointscenePointcloud,
  ogcApiPointscenePanorama,
  roadNetworkHistory,
  ogcApiStreamTrajectory,
  ogcApiPointcloud,
  ogcApiOrthoStrip,
  ogcApiPanoramaSequence,
  processingResult,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
