import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RoadNetwork from './road-network';
import RoadNetworkDetail from './road-network-detail';
import RoadNetworkUpdate from './road-network-update';
import RoadNetworkDeleteDialog from './road-network-delete-dialog';

const RoadNetworkRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RoadNetwork />} />
    <Route path="new" element={<RoadNetworkUpdate />} />
    <Route path=":id">
      <Route index element={<RoadNetworkDetail />} />
      <Route path="edit" element={<RoadNetworkUpdate />} />
      <Route path="delete" element={<RoadNetworkDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RoadNetworkRoutes;
