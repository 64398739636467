import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IStream } from 'app/shared/model/stream.model';
import { getEntities } from './stream.reducer';

export const Stream = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const streamList = useAppSelector(state => state.stream.entities);
  const loading = useAppSelector(state => state.stream.loading);
  const totalItems = useAppSelector(state => state.stream.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2 id="stream-heading" data-cy="StreamHeading">
        Streams
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} /> Refresh list
          </Button>
          <Link to="/stream/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp; Create a new Stream
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {streamList && streamList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  ID <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('uuid')}>
                  Uuid <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('name')}>
                  Name <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('dateCreated')}>
                  Date Created <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('streamStartDate')}>
                  Stream Start Date <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('streamEndDate')}>
                  Stream End Date <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('processedLengthInKm')}>
                  Processed Length In Km <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('ogcApiTrajectoryUrl')}>
                  Ogc Api Trajectory Url <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('s3Path')}>
                  S 3 Path <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('processingCostInUsd')}>
                  Processing Cost In Usd <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('processingTimeInSeconds')}>
                  Processing Time In Seconds <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('s3ThumbnailImage')}>
                  S 3 Thumbnail Image <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('status')}>
                  Status <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('processingStatus')}>
                  Processing Status <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  Trajectory <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  Ortho Strip <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  Pointcloud <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  Panorama Sequence <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  Processing Result <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  Organization <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  Capture <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {streamList.map((stream, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/stream/${stream.id}`} color="link" size="sm">
                      {stream.id}
                    </Button>
                  </td>
                  <td>{stream.uuid}</td>
                  <td>{stream.name}</td>
                  <td>{stream.dateCreated ? <TextFormat type="date" value={stream.dateCreated} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>
                    {stream.streamStartDate ? <TextFormat type="date" value={stream.streamStartDate} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>{stream.streamEndDate ? <TextFormat type="date" value={stream.streamEndDate} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{stream.processedLengthInKm}</td>
                  <td>{stream.ogcApiTrajectoryUrl}</td>
                  <td>{stream.s3Path}</td>
                  <td>{stream.processingCostInUsd}</td>
                  <td>{stream.processingTimeInSeconds}</td>
                  <td>{stream.s3ThumbnailImage}</td>
                  <td>{stream.status}</td>
                  <td>{stream.processingStatus}</td>
                  <td>
                    {stream.trajectory ? <Link to={`/ogc-api-stream-trajectory/${stream.trajectory.id}`}>{stream.trajectory.id}</Link> : ''}
                  </td>
                  <td>
                    {stream.orthoStrip ? <Link to={`/ogc-api-ortho-strip/${stream.orthoStrip.id}`}>{stream.orthoStrip.id}</Link> : ''}
                  </td>
                  <td>{stream.pointcloud ? <Link to={`/ogc-api-pointcloud/${stream.pointcloud.id}`}>{stream.pointcloud.id}</Link> : ''}</td>
                  <td>
                    {stream.panoramaSequence ? (
                      <Link to={`/ogc-api-panorama-sequence/${stream.panoramaSequence.id}`}>{stream.panoramaSequence.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {stream.processingResult ? (
                      <Link to={`/processing-result/${stream.processingResult.id}`}>{stream.processingResult.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{stream.organization ? <Link to={`/organization/${stream.organization.id}`}>{stream.organization.id}</Link> : ''}</td>
                  <td>{stream.capture ? <Link to={`/capture/${stream.capture.id}`}>{stream.capture.id}</Link> : ''}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/stream/${stream.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/stream/${stream.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/stream/${stream.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Delete</span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Streams found</div>
        )}
      </div>
      {totalItems ? (
        <div className={streamList && streamList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Stream;
