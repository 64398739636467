import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import StatusUpdateHistory from './status-update-history';
import StatusUpdateHistoryDetail from './status-update-history-detail';
import StatusUpdateHistoryUpdate from './status-update-history-update';
import StatusUpdateHistoryDeleteDialog from './status-update-history-delete-dialog';

const StatusUpdateHistoryRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<StatusUpdateHistory />} />
    <Route path="new" element={<StatusUpdateHistoryUpdate />} />
    <Route path=":id">
      <Route index element={<StatusUpdateHistoryDetail />} />
      <Route path="edit" element={<StatusUpdateHistoryUpdate />} />
      <Route path="delete" element={<StatusUpdateHistoryDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default StatusUpdateHistoryRoutes;
