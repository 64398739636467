import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IProcessingResult } from 'app/shared/model/processing-result.model';
import { getEntities as getProcessingResults } from 'app/entities/processing-result/processing-result.reducer';
import { IOrganization } from 'app/shared/model/organization.model';
import { getEntities as getOrganizations } from 'app/entities/organization/organization.reducer';
import { ICountry } from 'app/shared/model/country.model';
import { getEntities as getCountries } from 'app/entities/country/country.reducer';
import { IUpload } from 'app/shared/model/upload.model';
import { getEntities as getUploads } from 'app/entities/upload/upload.reducer';
import { ICapture } from 'app/shared/model/capture.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { ProcessingStatus } from 'app/shared/model/enumerations/processing-status.model';
import { getEntity, updateEntity, createEntity, reset } from './capture.reducer';

export const CaptureUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const processingResults = useAppSelector(state => state.processingResult.entities);
  const organizations = useAppSelector(state => state.organization.entities);
  const countries = useAppSelector(state => state.country.entities);
  const uploads = useAppSelector(state => state.upload.entities);
  const captureEntity = useAppSelector(state => state.capture.entity);
  const loading = useAppSelector(state => state.capture.loading);
  const updating = useAppSelector(state => state.capture.updating);
  const updateSuccess = useAppSelector(state => state.capture.updateSuccess);
  const statusValues = Object.keys(Status);
  const processingStatusValues = Object.keys(ProcessingStatus);

  const handleClose = () => {
    navigate('/capture' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getProcessingResults({}));
    dispatch(getOrganizations({}));
    dispatch(getCountries({}));
    dispatch(getUploads({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.dateCreated = convertDateTimeToServer(values.dateCreated);
    values.captureStartDate = convertDateTimeToServer(values.captureStartDate);
    values.captureEndDate = convertDateTimeToServer(values.captureEndDate);

    const entity = {
      ...captureEntity,
      ...values,
      processingResult: processingResults.find(it => it.id.toString() === values.processingResult.toString()),
      organization: organizations.find(it => it.id.toString() === values.organization.toString()),
      country: countries.find(it => it.id.toString() === values.country.toString()),
      upload: uploads.find(it => it.id.toString() === values.upload.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          dateCreated: displayDefaultDateTime(),
          captureStartDate: displayDefaultDateTime(),
          captureEndDate: displayDefaultDateTime(),
        }
      : {
          status: 'PLANNED',
          processingStatus: 'UNPROCESSED',
          ...captureEntity,
          dateCreated: convertDateTimeFromServer(captureEntity.dateCreated),
          captureStartDate: convertDateTimeFromServer(captureEntity.captureStartDate),
          captureEndDate: convertDateTimeFromServer(captureEntity.captureEndDate),
          processingResult: captureEntity?.processingResult?.id,
          organization: captureEntity?.organization?.id,
          country: captureEntity?.country?.id,
          upload: captureEntity?.upload?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="uploadsapiApp.capture.home.createOrEditLabel" data-cy="CaptureCreateUpdateHeading">
            Create or edit a Capture
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? <ValidatedField name="id" required readOnly id="capture-id" label="ID" validate={{ required: true }} /> : null}
              <ValidatedField
                label="Uuid"
                id="capture-uuid"
                name="uuid"
                data-cy="uuid"
                type="text"
                validate={{
                  required: { value: true, message: 'This field is required.' },
                }}
              />
              <ValidatedField label="Name" id="capture-name" name="name" data-cy="name" type="text" />
              <ValidatedField label="S 3 Path" id="capture-s3Path" name="s3Path" data-cy="s3Path" type="text" />
              <ValidatedField label="Status" id="capture-status" name="status" data-cy="status" type="select">
                {statusValues.map(status => (
                  <option value={status} key={status}>
                    {status}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label="Processing Status"
                id="capture-processingStatus"
                name="processingStatus"
                data-cy="processingStatus"
                type="select"
              >
                {processingStatusValues.map(processingStatus => (
                  <option value={processingStatus} key={processingStatus}>
                    {processingStatus}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label="Date Created"
                id="capture-dateCreated"
                name="dateCreated"
                data-cy="dateCreated"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label="Capture Start Date"
                id="capture-captureStartDate"
                name="captureStartDate"
                data-cy="captureStartDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label="Capture End Date"
                id="capture-captureEndDate"
                name="captureEndDate"
                data-cy="captureEndDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField label="Length In Km" id="capture-lengthInKm" name="lengthInKm" data-cy="lengthInKm" type="text" />
              <ValidatedField
                label="S 3 Thumbnail Image"
                id="capture-s3ThumbnailImage"
                name="s3ThumbnailImage"
                data-cy="s3ThumbnailImage"
                type="text"
              />
              <ValidatedField label="Osm Lvl 8" id="capture-osmLvl8" name="osmLvl8" data-cy="osmLvl8" type="text" />
              <ValidatedField
                id="capture-processingResult"
                name="processingResult"
                data-cy="processingResult"
                label="Processing Result"
                type="select"
              >
                <option value="" key="0" />
                {processingResults
                  ? processingResults.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="capture-organization" name="organization" data-cy="organization" label="Organization" type="select">
                <option value="" key="0" />
                {organizations
                  ? organizations.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="capture-country" name="country" data-cy="country" label="Country" type="select">
                <option value="" key="0" />
                {countries
                  ? countries.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="capture-upload" name="upload" data-cy="upload" label="Upload" type="select">
                <option value="" key="0" />
                {uploads
                  ? uploads.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/capture" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CaptureUpdate;
