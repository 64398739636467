import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IProject } from 'app/shared/model/project.model';
import { getEntities as getProjects } from 'app/entities/project/project.reducer';
import { IUpload } from 'app/shared/model/upload.model';
import { getEntities as getUploads } from 'app/entities/upload/upload.reducer';
import { ICapture } from 'app/shared/model/capture.model';
import { getEntities as getCaptures } from 'app/entities/capture/capture.reducer';
import { IStream } from 'app/shared/model/stream.model';
import { getEntities as getStreams } from 'app/entities/stream/stream.reducer';
import { IProcessingResult } from 'app/shared/model/processing-result.model';
import { ResultType } from 'app/shared/model/enumerations/result-type.model';
import { getEntity, updateEntity, createEntity, reset } from './processing-result.reducer';

export const ProcessingResultUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const projects = useAppSelector(state => state.project.entities);
  const uploads = useAppSelector(state => state.upload.entities);
  const captures = useAppSelector(state => state.capture.entities);
  const streams = useAppSelector(state => state.stream.entities);
  const processingResultEntity = useAppSelector(state => state.processingResult.entity);
  const loading = useAppSelector(state => state.processingResult.loading);
  const updating = useAppSelector(state => state.processingResult.updating);
  const updateSuccess = useAppSelector(state => state.processingResult.updateSuccess);
  const resultTypeValues = Object.keys(ResultType);

  const handleClose = () => {
    navigate('/processing-result' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getProjects({}));
    dispatch(getUploads({}));
    dispatch(getCaptures({}));
    dispatch(getStreams({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...processingResultEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          resultType: 'ORTHOPHOTO',
          ...processingResultEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="uploadsapiApp.processingResult.home.createOrEditLabel" data-cy="ProcessingResultCreateUpdateHeading">
            Create or edit a Processing Result
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField name="id" required readOnly id="processing-result-id" label="ID" validate={{ required: true }} />
              ) : null}
              <ValidatedField label="Uuid" id="processing-result-uuid" name="uuid" data-cy="uuid" type="text" />
              <ValidatedField label="Result Type" id="processing-result-resultType" name="resultType" data-cy="resultType" type="select">
                {resultTypeValues.map(resultType => (
                  <option value={resultType} key={resultType}>
                    {resultType}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField label="Url" id="processing-result-url" name="url" data-cy="url" type="text" />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/processing-result" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProcessingResultUpdate;
