import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AwsRegion from './aws-region';
import AwsRegionDetail from './aws-region-detail';
import AwsRegionUpdate from './aws-region-update';
import AwsRegionDeleteDialog from './aws-region-delete-dialog';

const AwsRegionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AwsRegion />} />
    <Route path="new" element={<AwsRegionUpdate />} />
    <Route path=":id">
      <Route index element={<AwsRegionDetail />} />
      <Route path="edit" element={<AwsRegionUpdate />} />
      <Route path="delete" element={<AwsRegionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AwsRegionRoutes;
