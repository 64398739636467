import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import {} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './road-network.reducer';

export const RoadNetworkDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const roadNetworkEntity = useAppSelector(state => state.roadNetwork.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="roadNetworkDetailsHeading">Road Network</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{roadNetworkEntity.id}</dd>
          <dt>
            <span id="ogcApiRoadNetworkUrl">Ogc Api Road Network Url</span>
          </dt>
          <dd>{roadNetworkEntity.ogcApiRoadNetworkUrl}</dd>
          <dt>
            <span id="roadNetworkLengthKm">Road Network Length Km</span>
          </dt>
          <dd>{roadNetworkEntity.roadNetworkLengthKm}</dd>
          <dt>
            <span id="processedLenghtKm">Processed Lenght Km</span>
          </dt>
          <dd>{roadNetworkEntity.processedLenghtKm}</dd>
        </dl>
        <Button tag={Link} to="/road-network" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/road-network/${roadNetworkEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

export default RoadNetworkDetail;
