import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './stream.reducer';

export const StreamDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const streamEntity = useAppSelector(state => state.stream.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="streamDetailsHeading">Stream</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{streamEntity.id}</dd>
          <dt>
            <span id="uuid">Uuid</span>
          </dt>
          <dd>{streamEntity.uuid}</dd>
          <dt>
            <span id="name">Name</span>
          </dt>
          <dd>{streamEntity.name}</dd>
          <dt>
            <span id="dateCreated">Date Created</span>
          </dt>
          <dd>{streamEntity.dateCreated ? <TextFormat value={streamEntity.dateCreated} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="streamStartDate">Stream Start Date</span>
          </dt>
          <dd>
            {streamEntity.streamStartDate ? <TextFormat value={streamEntity.streamStartDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="streamEndDate">Stream End Date</span>
          </dt>
          <dd>
            {streamEntity.streamEndDate ? <TextFormat value={streamEntity.streamEndDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="processedLengthInKm">Processed Length In Km</span>
          </dt>
          <dd>{streamEntity.processedLengthInKm}</dd>
          <dt>
            <span id="ogcApiTrajectoryUrl">Ogc Api Trajectory Url</span>
          </dt>
          <dd>{streamEntity.ogcApiTrajectoryUrl}</dd>
          <dt>
            <span id="s3Path">S 3 Path</span>
          </dt>
          <dd>{streamEntity.s3Path}</dd>
          <dt>
            <span id="processingCostInUsd">Processing Cost In Usd</span>
          </dt>
          <dd>{streamEntity.processingCostInUsd}</dd>
          <dt>
            <span id="processingTimeInSeconds">Processing Time In Seconds</span>
          </dt>
          <dd>{streamEntity.processingTimeInSeconds}</dd>
          <dt>
            <span id="s3ThumbnailImage">S 3 Thumbnail Image</span>
          </dt>
          <dd>{streamEntity.s3ThumbnailImage}</dd>
          <dt>
            <span id="status">Status</span>
          </dt>
          <dd>{streamEntity.status}</dd>
          <dt>
            <span id="processingStatus">Processing Status</span>
          </dt>
          <dd>{streamEntity.processingStatus}</dd>
          <dt>Trajectory</dt>
          <dd>{streamEntity.trajectory ? streamEntity.trajectory.id : ''}</dd>
          <dt>Ortho Strip</dt>
          <dd>{streamEntity.orthoStrip ? streamEntity.orthoStrip.id : ''}</dd>
          <dt>Pointcloud</dt>
          <dd>{streamEntity.pointcloud ? streamEntity.pointcloud.id : ''}</dd>
          <dt>Panorama Sequence</dt>
          <dd>{streamEntity.panoramaSequence ? streamEntity.panoramaSequence.id : ''}</dd>
          <dt>Processing Result</dt>
          <dd>{streamEntity.processingResult ? streamEntity.processingResult.id : ''}</dd>
          <dt>Organization</dt>
          <dd>{streamEntity.organization ? streamEntity.organization.id : ''}</dd>
          <dt>Capture</dt>
          <dd>{streamEntity.capture ? streamEntity.capture.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/stream" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/stream/${streamEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

export default StreamDetail;
