import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IProject } from 'app/shared/model/project.model';
import { getEntities as getProjects } from 'app/entities/project/project.reducer';
import { IProjectConf } from 'app/shared/model/project-conf.model';
import { getEntity, updateEntity, createEntity, reset } from './project-conf.reducer';

export const ProjectConfUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const projects = useAppSelector(state => state.project.entities);
  const projectConfEntity = useAppSelector(state => state.projectConf.entity);
  const loading = useAppSelector(state => state.projectConf.loading);
  const updating = useAppSelector(state => state.projectConf.updating);
  const updateSuccess = useAppSelector(state => state.projectConf.updateSuccess);

  const handleClose = () => {
    navigate('/project-conf' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getProjects({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.timeFrameStart = convertDateTimeToServer(values.timeFrameStart);
    values.timeFrameEnd = convertDateTimeToServer(values.timeFrameEnd);

    const entity = {
      ...projectConfEntity,
      ...values,
      project: projects.find(it => it.id.toString() === values.project.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          timeFrameStart: null, // custom
          timeFrameEnd: null, // custom
        }
      : {
          ...projectConfEntity,
          timeFrameStart: convertDateTimeFromServer(projectConfEntity.timeFrameStart),
          timeFrameEnd: convertDateTimeFromServer(projectConfEntity.timeFrameEnd),
          project: projectConfEntity?.project?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="uploadsapiApp.projectConf.home.createOrEditLabel" data-cy="ProjectConfCreateUpdateHeading">
            Create or edit a Project Conf
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? <ValidatedField name="id" required readOnly id="project-conf-id" label="ID" validate={{ required: true }} /> : null}
              <ValidatedField label="Name" id="project-conf-name" name="name" data-cy="name" type="text" />
              <ValidatedField label="Camera Sn" id="project-conf-cameraSn" name="cameraSn" data-cy="cameraSn" type="text" />
              <ValidatedField label="Conf" id="project-conf-conf" name="conf" data-cy="conf" type="textarea" />
              <ValidatedField
                label="Time Frame Start"
                id="project-conf-timeFrameStart"
                name="timeFrameStart"
                data-cy="timeFrameStart"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label="Time Frame End"
                id="project-conf-timeFrameEnd"
                name="timeFrameEnd"
                data-cy="timeFrameEnd"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField id="project-conf-project" name="project" data-cy="project" label="Project" type="select">
                <option value="" key="0" />
                {projects
                  ? projects.map(otherEntity => (
                      // custom code inside option
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name + " uuid: " + otherEntity.uuid + " id: " + otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/project-conf" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProjectConfUpdate;
