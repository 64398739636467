import dayjs from 'dayjs';
import { IRoadNetwork } from 'app/shared/model/road-network.model';
import { IProcessingResult } from 'app/shared/model/processing-result.model';
import { IProjectConf } from 'app/shared/model/project-conf.model';
import { IUpload } from 'app/shared/model/upload.model';
import { IOrganization } from 'app/shared/model/organization.model';
import { ICountry } from 'app/shared/model/country.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface IProject {
  id?: number;
  uuid?: string;
  name?: string | null;
  pipelineName?: string | null;
  automaticallyProcessed?: boolean | null;
  s3Bucket?: string | null;
  status?: Status | null;
  plannedKm?: number | null;
  plannedDataCaptureStart?: string | null;
  plannedDataCaptureEnd?: string | null;
  roadNetwork?: IRoadNetwork | null;
  processingResult?: IProcessingResult | null;
  projectConfs?: IProjectConf[] | null;
  uploads?: IUpload[] | null;
  organization?: IOrganization | null;
  country?: ICountry | null;
}

export const defaultValue: Readonly<IProject> = {
  automaticallyProcessed: false,
};
