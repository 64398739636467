import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IRoadNetwork } from 'app/shared/model/road-network.model';
import { getEntities as getRoadNetworks } from 'app/entities/road-network/road-network.reducer';
import { IProcessingResult } from 'app/shared/model/processing-result.model';
import { getEntities as getProcessingResults } from 'app/entities/processing-result/processing-result.reducer';
import { IOrganization } from 'app/shared/model/organization.model';
import { getEntities as getOrganizations } from 'app/entities/organization/organization.reducer';
import { ICountry } from 'app/shared/model/country.model';
import { getEntities as getCountries } from 'app/entities/country/country.reducer';
import { IProject } from 'app/shared/model/project.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { getEntity, updateEntity, createEntity, reset } from './project.reducer';

export const ProjectUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const roadNetworks = useAppSelector(state => state.roadNetwork.entities);
  const processingResults = useAppSelector(state => state.processingResult.entities);
  const organizations = useAppSelector(state => state.organization.entities);
  const countries = useAppSelector(state => state.country.entities);
  const projectEntity = useAppSelector(state => state.project.entity);
  const loading = useAppSelector(state => state.project.loading);
  const updating = useAppSelector(state => state.project.updating);
  const updateSuccess = useAppSelector(state => state.project.updateSuccess);
  const statusValues = Object.keys(Status);

  const handleClose = () => {
    navigate('/project' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getRoadNetworks({}));
    dispatch(getProcessingResults({}));
    dispatch(getOrganizations({}));
    dispatch(getCountries({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.plannedDataCaptureStart = convertDateTimeToServer(values.plannedDataCaptureStart);
    values.plannedDataCaptureEnd = convertDateTimeToServer(values.plannedDataCaptureEnd);

    const entity = {
      ...projectEntity,
      ...values,
      roadNetwork: roadNetworks.find(it => it.id.toString() === values.roadNetwork.toString()),
      processingResult: processingResults.find(it => it.id.toString() === values.processingResult.toString()),
      organization: organizations.find(it => it.id.toString() === values.organization.toString()),
      country: countries.find(it => it.id.toString() === values.country.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          plannedDataCaptureStart: displayDefaultDateTime(),
          plannedDataCaptureEnd: displayDefaultDateTime(),
        }
      : {
          status: 'PLANNED',
          ...projectEntity,
          plannedDataCaptureStart: convertDateTimeFromServer(projectEntity.plannedDataCaptureStart),
          plannedDataCaptureEnd: convertDateTimeFromServer(projectEntity.plannedDataCaptureEnd),
          roadNetwork: projectEntity?.roadNetwork?.id,
          processingResult: projectEntity?.processingResult?.id,
          organization: projectEntity?.organization?.id,
          country: projectEntity?.country?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="uploadsapiApp.project.home.createOrEditLabel" data-cy="ProjectCreateUpdateHeading">
            Create or edit a Project
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? <ValidatedField name="id" required readOnly id="project-id" label="ID" validate={{ required: true }} /> : null}
              <ValidatedField
                label="Uuid"
                id="project-uuid"
                name="uuid"
                data-cy="uuid"
                type="text"
                validate={{
                  required: { value: true, message: 'This field is required.' },
                }}
              />
              <ValidatedField label="Name" id="project-name" name="name" data-cy="name" type="text" />
              <ValidatedField label="Pipeline Name" id="project-pipelineName" name="pipelineName" data-cy="pipelineName" type="text" />
              <ValidatedField
                label="Automatically Processed"
                id="project-automaticallyProcessed"
                name="automaticallyProcessed"
                data-cy="automaticallyProcessed"
                check
                type="checkbox"
              />
              <ValidatedField label="S 3 Bucket" id="project-s3Bucket" name="s3Bucket" data-cy="s3Bucket" type="text" />
              <ValidatedField label="Status" id="project-status" name="status" data-cy="status" type="select">
                {statusValues.map(status => (
                  <option value={status} key={status}>
                    {status}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField label="Planned Km" id="project-plannedKm" name="plannedKm" data-cy="plannedKm" type="text" />
              <ValidatedField
                label="Planned Data Capture Start"
                id="project-plannedDataCaptureStart"
                name="plannedDataCaptureStart"
                data-cy="plannedDataCaptureStart"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label="Planned Data Capture End"
                id="project-plannedDataCaptureEnd"
                name="plannedDataCaptureEnd"
                data-cy="plannedDataCaptureEnd"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField id="project-roadNetwork" name="roadNetwork" data-cy="roadNetwork" label="Road Network" type="select">
                <option value="" key="0" />
                {roadNetworks
                  ? roadNetworks.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="project-processingResult"
                name="processingResult"
                data-cy="processingResult"
                label="Processing Result"
                type="select"
              >
                <option value="" key="0" />
                {processingResults
                  ? processingResults.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="project-organization" name="organization" data-cy="organization" label="Organization" type="select">
                <option value="" key="0" />
                {organizations
                  ? organizations.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="project-country" name="country" data-cy="country" label="Country" type="select">
                <option value="" key="0" />
                {countries
                  ? countries.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/project" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProjectUpdate;
