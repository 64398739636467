import dayjs from 'dayjs';
import { IOgcApiStreamTrajectory } from 'app/shared/model/ogc-api-stream-trajectory.model';
import { IOgcApiOrthoStrip } from 'app/shared/model/ogc-api-ortho-strip.model';
import { IOgcApiPointcloud } from 'app/shared/model/ogc-api-pointcloud.model';
import { IOgcApiPanoramaSequence } from 'app/shared/model/ogc-api-panorama-sequence.model';
import { IProcessingResult } from 'app/shared/model/processing-result.model';
import { IOgcApiPointscenePointcloud } from 'app/shared/model/ogc-api-pointscene-pointcloud.model';
import { IOgcApiPointscenePanorama } from 'app/shared/model/ogc-api-pointscene-panorama.model';
import { IOrganization } from 'app/shared/model/organization.model';
import { ICapture } from 'app/shared/model/capture.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { ProcessingStatus } from 'app/shared/model/enumerations/processing-status.model';

export interface IStream {
  id?: number;
  uuid?: string;
  name?: string | null;
  dateCreated?: string | null;
  streamStartDate?: string | null;
  streamEndDate?: string | null;
  processedLengthInKm?: number | null;
  ogcApiTrajectoryUrl?: string | null;
  s3Path?: string | null;
  processingCostInUsd?: number | null;
  processingTimeInSeconds?: number | null;
  s3ThumbnailImage?: string | null;
  status?: Status | null;
  processingStatus?: ProcessingStatus | null;
  trajectory?: IOgcApiStreamTrajectory | null;
  orthoStrip?: IOgcApiOrthoStrip | null;
  pointcloud?: IOgcApiPointcloud | null;
  panoramaSequence?: IOgcApiPanoramaSequence | null;
  processingResult?: IProcessingResult | null;
  pointscenePointclouds?: IOgcApiPointscenePointcloud[] | null;
  pointscenePanoramas?: IOgcApiPointscenePanorama[] | null;
  organization?: IOrganization | null;
  capture?: ICapture | null;
}

export const defaultValue: Readonly<IStream> = {};
