import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RoadNetworkHistory from './road-network-history';
import RoadNetworkHistoryDetail from './road-network-history-detail';
import RoadNetworkHistoryUpdate from './road-network-history-update';
import RoadNetworkHistoryDeleteDialog from './road-network-history-delete-dialog';

const RoadNetworkHistoryRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RoadNetworkHistory />} />
    <Route path="new" element={<RoadNetworkHistoryUpdate />} />
    <Route path=":id">
      <Route index element={<RoadNetworkHistoryDetail />} />
      <Route path="edit" element={<RoadNetworkHistoryUpdate />} />
      <Route path="delete" element={<RoadNetworkHistoryDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RoadNetworkHistoryRoutes;
