export enum UploadStatus {
  UPLOAD_NOT_STARTED = 'UPLOAD_NOT_STARTED',

  UPLOAD_STARTED = 'UPLOAD_STARTED',

  UPLOAD_FAILED = 'UPLOAD_FAILED',

  UPLOAD_ABORTED = 'UPLOAD_ABORTED',

  UPLOADED = 'UPLOADED',

  PROCESSING = 'PROCESSING',

  PROCESSED_SUCCESS = 'PROCESSED_SUCCESS',

  PROCESSED_ERROR = 'PROCESSED_ERROR',

  ARCHIVED = 'ARCHIVED',
}
