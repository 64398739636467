import React from 'react';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/organization">
        Organization
      </MenuItem>
      <MenuItem icon="asterisk" to="/project">
        Project
      </MenuItem>
      <MenuItem icon="asterisk" to="/aws-region">
        Aws Region
      </MenuItem>
      <MenuItem icon="asterisk" to="/stream">
        Stream
      </MenuItem>
      <MenuItem icon="asterisk" to="/upload">
        Upload
      </MenuItem>
      <MenuItem icon="asterisk" to="/country">
        Country
      </MenuItem>
      <MenuItem icon="asterisk" to="/status-update-history">
        Status Update History
      </MenuItem>
      <MenuItem icon="asterisk" to="/project-conf">
        Project Conf
      </MenuItem>
      <MenuItem icon="asterisk" to="/road-network">
        Road Network
      </MenuItem>
      <MenuItem icon="asterisk" to="/capture">
        Capture
      </MenuItem>
      <MenuItem icon="asterisk" to="/ogc-api-pointscene-pointcloud">
        Ogc Api Pointscene Pointcloud
      </MenuItem>
      <MenuItem icon="asterisk" to="/ogc-api-pointscene-panorama">
        Ogc Api Pointscene Panorama
      </MenuItem>
      <MenuItem icon="asterisk" to="/road-network-history">
        Road Network History
      </MenuItem>
      <MenuItem icon="asterisk" to="/ogc-api-stream-trajectory">
        Ogc Api Stream Trajectory
      </MenuItem>
      <MenuItem icon="asterisk" to="/ogc-api-pointcloud">
        Ogc Api Pointcloud
      </MenuItem>
      <MenuItem icon="asterisk" to="/ogc-api-ortho-strip">
        Ogc Api Ortho Strip
      </MenuItem>
      <MenuItem icon="asterisk" to="/ogc-api-panorama-sequence">
        Ogc Api Panorama Sequence
      </MenuItem>
      <MenuItem icon="asterisk" to="/processing-result">
        Processing Result
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
