import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProcessingResult from './processing-result';
import ProcessingResultDetail from './processing-result-detail';
import ProcessingResultUpdate from './processing-result-update';
import ProcessingResultDeleteDialog from './processing-result-delete-dialog';

const ProcessingResultRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProcessingResult />} />
    <Route path="new" element={<ProcessingResultUpdate />} />
    <Route path=":id">
      <Route index element={<ProcessingResultDetail />} />
      <Route path="edit" element={<ProcessingResultUpdate />} />
      <Route path="delete" element={<ProcessingResultDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProcessingResultRoutes;
