export enum ResultType {
  ORTHOPHOTO = 'ORTHOPHOTO',

  PANORAMIC_IMAGE_CUBEMAP = 'PANORAMIC_IMAGE_CUBEMAP',

  PANORAMIC_IMAGE_EQUIRECT = 'PANORAMIC_IMAGE_EQUIRECT',

  POINTCLOUD_RAW = 'POINTCLOUD_RAW',

  POINTCLOUD_RGB_CLASSIFIED = 'POINTCLOUD_RGB_CLASSIFIED',

  ROAD_DEFECT_GPKG = 'ROAD_DEFECT_GPKG',

  ROAD_DEFECT_REPORT_CSV = 'ROAD_DEFECT_REPORT_CSV',

  ROAD_DEFECT_REPORT_GPKG = 'ROAD_DEFECT_REPORT_GPKG',
}
