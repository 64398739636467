import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import OgcApiStreamTrajectory from './ogc-api-stream-trajectory';
import OgcApiStreamTrajectoryDetail from './ogc-api-stream-trajectory-detail';
import OgcApiStreamTrajectoryUpdate from './ogc-api-stream-trajectory-update';
import OgcApiStreamTrajectoryDeleteDialog from './ogc-api-stream-trajectory-delete-dialog';

const OgcApiStreamTrajectoryRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<OgcApiStreamTrajectory />} />
    <Route path="new" element={<OgcApiStreamTrajectoryUpdate />} />
    <Route path=":id">
      <Route index element={<OgcApiStreamTrajectoryDetail />} />
      <Route path="edit" element={<OgcApiStreamTrajectoryUpdate />} />
      <Route path="delete" element={<OgcApiStreamTrajectoryDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default OgcApiStreamTrajectoryRoutes;
