import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import OgcApiPointscenePointcloud from './ogc-api-pointscene-pointcloud';
import OgcApiPointscenePointcloudDetail from './ogc-api-pointscene-pointcloud-detail';
import OgcApiPointscenePointcloudUpdate from './ogc-api-pointscene-pointcloud-update';
import OgcApiPointscenePointcloudDeleteDialog from './ogc-api-pointscene-pointcloud-delete-dialog';

const OgcApiPointscenePointcloudRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<OgcApiPointscenePointcloud />} />
    <Route path="new" element={<OgcApiPointscenePointcloudUpdate />} />
    <Route path=":id">
      <Route index element={<OgcApiPointscenePointcloudDetail />} />
      <Route path="edit" element={<OgcApiPointscenePointcloudUpdate />} />
      <Route path="delete" element={<OgcApiPointscenePointcloudDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default OgcApiPointscenePointcloudRoutes;
