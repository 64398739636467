export enum ProcessingStatus {
  UNPROCESSED = 'UNPROCESSED',

  PROCESSING_REQUESTED = 'PROCESSING_REQUESTED',

  PROCESSING = 'PROCESSING',

  PROCESSED_SUCCESS = 'PROCESSED_SUCCESS',

  PROCESSED_MANUALLY = 'PROCESSED_MANUALLY',

  PROCESSED_ERROR = 'PROCESSED_ERROR',
}
