import { IProject } from 'app/shared/model/project.model';
import { IUpload } from 'app/shared/model/upload.model';
import { ICapture } from 'app/shared/model/capture.model';
import { IStream } from 'app/shared/model/stream.model';

export interface IOrganization {
  id?: number;
  uuid?: string;
  name?: string | null;
  projects?: IProject[] | null;
  uploads?: IUpload[] | null;
  captures?: ICapture[] | null;
  streams?: IStream[] | null;
}

export const defaultValue: Readonly<IOrganization> = {};
