import { ICapture } from 'app/shared/model/capture.model';
import { IProject } from 'app/shared/model/project.model';

export interface ICountry {
  id?: number;
  name?: string | null;
  alpha2?: string | null;
  alpha3?: string | null;
  code?: number | null;
  captures?: ICapture[] | null;
  projects?: IProject[] | null;
}

export const defaultValue: Readonly<ICountry> = {};
