import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Capture from './capture';
import CaptureDetail from './capture-detail';
import CaptureUpdate from './capture-update';
import CaptureDeleteDialog from './capture-delete-dialog';

const CaptureRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Capture />} />
    <Route path="new" element={<CaptureUpdate />} />
    <Route path=":id">
      <Route index element={<CaptureDetail />} />
      <Route path="edit" element={<CaptureUpdate />} />
      <Route path="delete" element={<CaptureDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CaptureRoutes;
