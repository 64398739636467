import { IProject } from 'app/shared/model/project.model';
import { IUpload } from 'app/shared/model/upload.model';
import { ICapture } from 'app/shared/model/capture.model';
import { IStream } from 'app/shared/model/stream.model';
import { ResultType } from 'app/shared/model/enumerations/result-type.model';

export interface IProcessingResult {
  id?: number;
  uuid?: string | null;
  resultType?: ResultType | null;
  url?: string | null;
  project?: IProject | null;
  upload?: IUpload | null;
  capture?: ICapture | null;
  stream?: IStream | null;
}

export const defaultValue: Readonly<IProcessingResult> = {};
