import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './project-conf.reducer';

export const ProjectConfDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const projectConfEntity = useAppSelector(state => state.projectConf.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="projectConfDetailsHeading">Project Conf</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{projectConfEntity.id}</dd>
          <dt>
            <span id="name">Name</span>
          </dt>
          <dd>{projectConfEntity.name}</dd>
          <dt>
            <span id="cameraSn">Camera Sn</span>
          </dt>
          <dd>{projectConfEntity.cameraSn}</dd>
          <dt>
            <span id="conf">Conf</span>
          </dt>
          <dd>{projectConfEntity.conf}</dd>
          <dt>
            <span id="timeFrameStart">Time Frame Start</span>
          </dt>
          <dd>
            {projectConfEntity.timeFrameStart ? (
              <TextFormat value={projectConfEntity.timeFrameStart} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="timeFrameEnd">Time Frame End</span>
          </dt>
          <dd>
            {projectConfEntity.timeFrameEnd ? (
              <TextFormat value={projectConfEntity.timeFrameEnd} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>Project</dt>
          <dd>{projectConfEntity.project ? projectConfEntity.project.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/project-conf" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/project-conf/${projectConfEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

export default ProjectConfDetail;
