import dayjs from 'dayjs';
import { IProcessingResult } from 'app/shared/model/processing-result.model';
import { IStream } from 'app/shared/model/stream.model';
import { IOrganization } from 'app/shared/model/organization.model';
import { ICountry } from 'app/shared/model/country.model';
import { IUpload } from 'app/shared/model/upload.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { ProcessingStatus } from 'app/shared/model/enumerations/processing-status.model';

export interface ICapture {
  id?: number;
  uuid?: string;
  name?: string | null;
  s3Path?: string | null;
  status?: Status | null;
  processingStatus?: ProcessingStatus | null;
  dateCreated?: string | null;
  captureStartDate?: string | null;
  captureEndDate?: string | null;
  lengthInKm?: number | null;
  s3ThumbnailImage?: string | null;
  osmLvl8?: string | null;
  processingResult?: IProcessingResult | null;
  streams?: IStream[] | null;
  organization?: IOrganization | null;
  country?: ICountry | null;
  upload?: IUpload | null;
}

export const defaultValue: Readonly<ICapture> = {};
