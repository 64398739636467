import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IOgcApiStreamTrajectory } from 'app/shared/model/ogc-api-stream-trajectory.model';
import { getEntities as getOgcApiStreamTrajectories } from 'app/entities/ogc-api-stream-trajectory/ogc-api-stream-trajectory.reducer';
import { IOgcApiOrthoStrip } from 'app/shared/model/ogc-api-ortho-strip.model';
import { getEntities as getOgcApiOrthoStrips } from 'app/entities/ogc-api-ortho-strip/ogc-api-ortho-strip.reducer';
import { IOgcApiPointcloud } from 'app/shared/model/ogc-api-pointcloud.model';
import { getEntities as getOgcApiPointclouds } from 'app/entities/ogc-api-pointcloud/ogc-api-pointcloud.reducer';
import { IOgcApiPanoramaSequence } from 'app/shared/model/ogc-api-panorama-sequence.model';
import { getEntities as getOgcApiPanoramaSequences } from 'app/entities/ogc-api-panorama-sequence/ogc-api-panorama-sequence.reducer';
import { IProcessingResult } from 'app/shared/model/processing-result.model';
import { getEntities as getProcessingResults } from 'app/entities/processing-result/processing-result.reducer';
import { IOrganization } from 'app/shared/model/organization.model';
import { getEntities as getOrganizations } from 'app/entities/organization/organization.reducer';
import { ICapture } from 'app/shared/model/capture.model';
import { getEntities as getCaptures } from 'app/entities/capture/capture.reducer';
import { IStream } from 'app/shared/model/stream.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { ProcessingStatus } from 'app/shared/model/enumerations/processing-status.model';
import { getEntity, updateEntity, createEntity, reset } from './stream.reducer';

export const StreamUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const ogcApiStreamTrajectories = useAppSelector(state => state.ogcApiStreamTrajectory.entities);
  const ogcApiOrthoStrips = useAppSelector(state => state.ogcApiOrthoStrip.entities);
  const ogcApiPointclouds = useAppSelector(state => state.ogcApiPointcloud.entities);
  const ogcApiPanoramaSequences = useAppSelector(state => state.ogcApiPanoramaSequence.entities);
  const processingResults = useAppSelector(state => state.processingResult.entities);
  const organizations = useAppSelector(state => state.organization.entities);
  const captures = useAppSelector(state => state.capture.entities);
  const streamEntity = useAppSelector(state => state.stream.entity);
  const loading = useAppSelector(state => state.stream.loading);
  const updating = useAppSelector(state => state.stream.updating);
  const updateSuccess = useAppSelector(state => state.stream.updateSuccess);
  const statusValues = Object.keys(Status);
  const processingStatusValues = Object.keys(ProcessingStatus);

  const handleClose = () => {
    navigate('/stream' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getOgcApiStreamTrajectories({}));
    dispatch(getOgcApiOrthoStrips({}));
    dispatch(getOgcApiPointclouds({}));
    dispatch(getOgcApiPanoramaSequences({}));
    dispatch(getProcessingResults({}));
    dispatch(getOrganizations({}));
    dispatch(getCaptures({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.dateCreated = convertDateTimeToServer(values.dateCreated);
    values.streamStartDate = convertDateTimeToServer(values.streamStartDate);
    values.streamEndDate = convertDateTimeToServer(values.streamEndDate);

    const entity = {
      ...streamEntity,
      ...values,
      trajectory: ogcApiStreamTrajectories.find(it => it.id.toString() === values.trajectory.toString()),
      orthoStrip: ogcApiOrthoStrips.find(it => it.id.toString() === values.orthoStrip.toString()),
      pointcloud: ogcApiPointclouds.find(it => it.id.toString() === values.pointcloud.toString()),
      panoramaSequence: ogcApiPanoramaSequences.find(it => it.id.toString() === values.panoramaSequence.toString()),
      processingResult: processingResults.find(it => it.id.toString() === values.processingResult.toString()),
      organization: organizations.find(it => it.id.toString() === values.organization.toString()),
      capture: captures.find(it => it.id.toString() === values.capture.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          dateCreated: displayDefaultDateTime(),
          streamStartDate: displayDefaultDateTime(),
          streamEndDate: displayDefaultDateTime(),
        }
      : {
          status: 'PLANNED',
          processingStatus: 'UNPROCESSED',
          ...streamEntity,
          dateCreated: convertDateTimeFromServer(streamEntity.dateCreated),
          streamStartDate: convertDateTimeFromServer(streamEntity.streamStartDate),
          streamEndDate: convertDateTimeFromServer(streamEntity.streamEndDate),
          trajectory: streamEntity?.trajectory?.id,
          orthoStrip: streamEntity?.orthoStrip?.id,
          pointcloud: streamEntity?.pointcloud?.id,
          panoramaSequence: streamEntity?.panoramaSequence?.id,
          processingResult: streamEntity?.processingResult?.id,
          organization: streamEntity?.organization?.id,
          capture: streamEntity?.capture?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="uploadsapiApp.stream.home.createOrEditLabel" data-cy="StreamCreateUpdateHeading">
            Create or edit a Stream
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? <ValidatedField name="id" required readOnly id="stream-id" label="ID" validate={{ required: true }} /> : null}
              <ValidatedField
                label="Uuid"
                id="stream-uuid"
                name="uuid"
                data-cy="uuid"
                type="text"
                validate={{
                  required: { value: true, message: 'This field is required.' },
                }}
              />
              <ValidatedField label="Name" id="stream-name" name="name" data-cy="name" type="text" />
              <ValidatedField
                label="Date Created"
                id="stream-dateCreated"
                name="dateCreated"
                data-cy="dateCreated"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label="Stream Start Date"
                id="stream-streamStartDate"
                name="streamStartDate"
                data-cy="streamStartDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label="Stream End Date"
                id="stream-streamEndDate"
                name="streamEndDate"
                data-cy="streamEndDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label="Processed Length In Km"
                id="stream-processedLengthInKm"
                name="processedLengthInKm"
                data-cy="processedLengthInKm"
                type="text"
              />
              <ValidatedField
                label="Ogc Api Trajectory Url"
                id="stream-ogcApiTrajectoryUrl"
                name="ogcApiTrajectoryUrl"
                data-cy="ogcApiTrajectoryUrl"
                type="text"
              />
              <ValidatedField label="S 3 Path" id="stream-s3Path" name="s3Path" data-cy="s3Path" type="text" />
              <ValidatedField
                label="Processing Cost In Usd"
                id="stream-processingCostInUsd"
                name="processingCostInUsd"
                data-cy="processingCostInUsd"
                type="text"
              />
              <ValidatedField
                label="Processing Time In Seconds"
                id="stream-processingTimeInSeconds"
                name="processingTimeInSeconds"
                data-cy="processingTimeInSeconds"
                type="text"
              />
              <ValidatedField
                label="S 3 Thumbnail Image"
                id="stream-s3ThumbnailImage"
                name="s3ThumbnailImage"
                data-cy="s3ThumbnailImage"
                type="text"
              />
              <ValidatedField label="Status" id="stream-status" name="status" data-cy="status" type="select">
                {statusValues.map(status => (
                  <option value={status} key={status}>
                    {status}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label="Processing Status"
                id="stream-processingStatus"
                name="processingStatus"
                data-cy="processingStatus"
                type="select"
              >
                {processingStatusValues.map(processingStatus => (
                  <option value={processingStatus} key={processingStatus}>
                    {processingStatus}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField id="stream-trajectory" name="trajectory" data-cy="trajectory" label="Trajectory" type="select">
                <option value="" key="0" />
                {ogcApiStreamTrajectories
                  ? ogcApiStreamTrajectories.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="stream-orthoStrip" name="orthoStrip" data-cy="orthoStrip" label="Ortho Strip" type="select">
                <option value="" key="0" />
                {ogcApiOrthoStrips
                  ? ogcApiOrthoStrips.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="stream-pointcloud" name="pointcloud" data-cy="pointcloud" label="Pointcloud" type="select">
                <option value="" key="0" />
                {ogcApiPointclouds
                  ? ogcApiPointclouds.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="stream-panoramaSequence"
                name="panoramaSequence"
                data-cy="panoramaSequence"
                label="Panorama Sequence"
                type="select"
              >
                <option value="" key="0" />
                {ogcApiPanoramaSequences
                  ? ogcApiPanoramaSequences.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="stream-processingResult"
                name="processingResult"
                data-cy="processingResult"
                label="Processing Result"
                type="select"
              >
                <option value="" key="0" />
                {processingResults
                  ? processingResults.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="stream-organization" name="organization" data-cy="organization" label="Organization" type="select">
                <option value="" key="0" />
                {organizations
                  ? organizations.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="stream-capture" name="capture" data-cy="capture" label="Capture" type="select">
                <option value="" key="0" />
                {captures
                  ? captures.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/stream" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default StreamUpdate;
