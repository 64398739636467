import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProjectConf from './project-conf';
import ProjectConfDetail from './project-conf-detail';
import ProjectConfUpdate from './project-conf-update';
import ProjectConfDeleteDialog from './project-conf-delete-dialog';

const ProjectConfRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProjectConf />} />
    <Route path="new" element={<ProjectConfUpdate />} />
    <Route path=":id">
      <Route index element={<ProjectConfDetail />} />
      <Route path="edit" element={<ProjectConfUpdate />} />
      <Route path="delete" element={<ProjectConfDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProjectConfRoutes;
