import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Organization from './organization';
import Project from './project';
import AwsRegion from './aws-region';
import Stream from './stream';
import Upload from './upload';
import Country from './country';
import StatusUpdateHistory from './status-update-history';
import ProjectConf from './project-conf';
import RoadNetwork from './road-network';
import Capture from './capture';
import OgcApiPointscenePointcloud from './ogc-api-pointscene-pointcloud';
import OgcApiPointscenePanorama from './ogc-api-pointscene-panorama';
import RoadNetworkHistory from './road-network-history';
import OgcApiStreamTrajectory from './ogc-api-stream-trajectory';
import OgcApiPointcloud from './ogc-api-pointcloud';
import OgcApiOrthoStrip from './ogc-api-ortho-strip';
import OgcApiPanoramaSequence from './ogc-api-panorama-sequence';
import ProcessingResult from './processing-result';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="organization/*" element={<Organization />} />
        <Route path="project/*" element={<Project />} />
        <Route path="aws-region/*" element={<AwsRegion />} />
        <Route path="stream/*" element={<Stream />} />
        <Route path="upload/*" element={<Upload />} />
        <Route path="country/*" element={<Country />} />
        <Route path="status-update-history/*" element={<StatusUpdateHistory />} />
        <Route path="project-conf/*" element={<ProjectConf />} />
        <Route path="road-network/*" element={<RoadNetwork />} />
        <Route path="capture/*" element={<Capture />} />
        <Route path="ogc-api-pointscene-pointcloud/*" element={<OgcApiPointscenePointcloud />} />
        <Route path="ogc-api-pointscene-panorama/*" element={<OgcApiPointscenePanorama />} />
        <Route path="road-network-history/*" element={<RoadNetworkHistory />} />
        <Route path="ogc-api-stream-trajectory/*" element={<OgcApiStreamTrajectory />} />
        <Route path="ogc-api-pointcloud/*" element={<OgcApiPointcloud />} />
        <Route path="ogc-api-ortho-strip/*" element={<OgcApiOrthoStrip />} />
        <Route path="ogc-api-panorama-sequence/*" element={<OgcApiPanoramaSequence />} />
        <Route path="processing-result/*" element={<ProcessingResult />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
