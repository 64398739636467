import dayjs from 'dayjs';
import { IProject } from 'app/shared/model/project.model';

export interface IProjectConf {
  id?: number;
  name?: string | null;
  cameraSn?: string | null;
  conf?: string | null;
  timeFrameStart?: string | null;
  timeFrameEnd?: string | null;
  project?: IProject | null;
}

export const defaultValue: Readonly<IProjectConf> = {};
