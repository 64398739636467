import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import OgcApiPointscenePanorama from './ogc-api-pointscene-panorama';
import OgcApiPointscenePanoramaDetail from './ogc-api-pointscene-panorama-detail';
import OgcApiPointscenePanoramaUpdate from './ogc-api-pointscene-panorama-update';
import OgcApiPointscenePanoramaDeleteDialog from './ogc-api-pointscene-panorama-delete-dialog';

const OgcApiPointscenePanoramaRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<OgcApiPointscenePanorama />} />
    <Route path="new" element={<OgcApiPointscenePanoramaUpdate />} />
    <Route path=":id">
      <Route index element={<OgcApiPointscenePanoramaDetail />} />
      <Route path="edit" element={<OgcApiPointscenePanoramaUpdate />} />
      <Route path="delete" element={<OgcApiPointscenePanoramaDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default OgcApiPointscenePanoramaRoutes;
