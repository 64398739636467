import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IProcessingResult } from 'app/shared/model/processing-result.model';
import { getEntities as getProcessingResults } from 'app/entities/processing-result/processing-result.reducer';
import { IOrganization } from 'app/shared/model/organization.model';
import { getEntities as getOrganizations } from 'app/entities/organization/organization.reducer';
import { IProject } from 'app/shared/model/project.model';
import { getEntities as getProjects } from 'app/entities/project/project.reducer';
import { IUpload } from 'app/shared/model/upload.model';
import { UploadStatus } from 'app/shared/model/enumerations/upload-status.model';
import { getEntity, updateEntity, createEntity, reset } from './upload.reducer';

export const UploadUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const processingResults = useAppSelector(state => state.processingResult.entities);
  const organizations = useAppSelector(state => state.organization.entities);
  const projects = useAppSelector(state => state.project.entities);
  const uploadEntity = useAppSelector(state => state.upload.entity);
  const loading = useAppSelector(state => state.upload.loading);
  const updating = useAppSelector(state => state.upload.updating);
  const updateSuccess = useAppSelector(state => state.upload.updateSuccess);
  const uploadStatusValues = Object.keys(UploadStatus);

  const handleClose = () => {
    navigate('/upload' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getProcessingResults({}));
    dispatch(getOrganizations({}));
    dispatch(getProjects({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.dateStarted = convertDateTimeToServer(values.dateStarted);
    values.dateFinished = convertDateTimeToServer(values.dateFinished);

    const entity = {
      ...uploadEntity,
      ...values,
      processingResult: processingResults.find(it => it.id.toString() === values.processingResult.toString()),
      organization: organizations.find(it => it.id.toString() === values.organization.toString()),
      project: projects.find(it => it.id.toString() === values.project.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          dateStarted: displayDefaultDateTime(),
          dateFinished: displayDefaultDateTime(),
        }
      : {
          status: 'UPLOAD_NOT_STARTED',
          ...uploadEntity,
          dateStarted: convertDateTimeFromServer(uploadEntity.dateStarted),
          dateFinished: convertDateTimeFromServer(uploadEntity.dateFinished),
          processingResult: uploadEntity?.processingResult?.id,
          organization: uploadEntity?.organization?.id,
          project: uploadEntity?.project?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="uploadsapiApp.upload.home.createOrEditLabel" data-cy="UploadCreateUpdateHeading">
            Create or edit a Upload
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? <ValidatedField name="id" required readOnly id="upload-id" label="ID" validate={{ required: true }} /> : null}
              <ValidatedField
                label="Uuid"
                id="upload-uuid"
                name="uuid"
                data-cy="uuid"
                type="text"
                validate={{
                  required: { value: true, message: 'This field is required.' },
                }}
              />
              <ValidatedField label="Name" id="upload-name" name="name" data-cy="name" type="text" />
              <ValidatedField
                label="Date Started"
                id="upload-dateStarted"
                name="dateStarted"
                data-cy="dateStarted"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label="Date Finished"
                id="upload-dateFinished"
                name="dateFinished"
                data-cy="dateFinished"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField label="Status" id="upload-status" name="status" data-cy="status" type="select">
                {uploadStatusValues.map(uploadStatus => (
                  <option value={uploadStatus} key={uploadStatus}>
                    {uploadStatus}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label="Uploader Person"
                id="upload-uploaderPerson"
                name="uploaderPerson"
                data-cy="uploaderPerson"
                type="text"
              />
              <ValidatedField
                id="upload-processingResult"
                name="processingResult"
                data-cy="processingResult"
                label="Processing Result"
                type="select"
              >
                <option value="" key="0" />
                {processingResults
                  ? processingResults.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="upload-organization" name="organization" data-cy="organization" label="Organization" type="select">
                <option value="" key="0" />
                {organizations
                  ? organizations.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="upload-project" name="project" data-cy="project" label="Project" type="select">
                <option value="" key="0" />
                {projects
                  ? projects.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/upload" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UploadUpdate;
